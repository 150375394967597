import "./login.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CustomAlert from "./modal";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const LoginComponent = () => {
  let query = useQuery();
  let token = query.get("token");
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);

  useEffect(() => {}, []);

  const saveTokensAndConnectEmail = (code) => {
    console.log("code", code);
    axios
      .post(
        "https://backend.lazyapply.com/saveTokenAndConnectEmail",
        {
          code,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (
          response?.data?.message &&
          response?.data?.message === "Successfully Linked"
        ) {
          setmessage("Permission Granted");
          settitle("Success!");
          setmodalshow(true);
          window.open('https://app.lazyapply.com/dashboard/lazyapply-x','_blank')
          setTimeout(() => {
            setmodalshow(false);
            window.close();
          }, 2000);
        } else if (
          response?.data?.message &&
          response?.data?.message === "Access not given to Lazyapply"
        ) {
          setmessage("Access not given to Lazyapply");
          settitle("Relogin again!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        } else {
          console.log("something went wrong");
          setmessage("Some error occured");
          settitle("Error!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("errorhai", err);
      });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      saveTokensAndConnectEmail(codeResponse.code);
      console.log("code", codeResponse.code);
    },
    onError: (err) => {
      //access_denied
      console.log("code err", err.error);
      setmessage("Access not given to Lazyapply");
      settitle("Relogin again!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 2000);
    },
    clientId:
      "118263103120-1ourv3amn189uosq88jfd5illheaagu9.apps.googleusercontent.com",
    access_type: "offline",
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
    uxMode: "popup",
  });

  return (
    <>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div className="loginGrid">
        <div className="loginGridChild">
          <div className="loginGridChildContent logoImg">
            <img src={"/assests/panda1.png"} alt="logo-lazyapply" />
          </div>

          <>
            <div className="loginGridChildContent googleLoginButton">
              <button onClick={() => login()} className="login-with-google-btn">
                Sign In
              </button>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const Login = () => {
  const CLIENT_ID = encodeURIComponent(
    "118263103120-1ourv3amn189uosq88jfd5illheaagu9.apps.googleusercontent.com"
  );
  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <LoginComponent />
      </GoogleOAuthProvider>
    </>
  );
};

export default Login;
